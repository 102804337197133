<template>
  <div class="new-ticket-posts">
    <div class="new-ticket-posts__header">
      <Heading level="4">{{ $t('new_ticket_post.title') }}</Heading>
    </div>
    <div v-if="customs.length" class="new-ticket-posts__list">
      <ButtonBase
        v-for="post in customs"
        :key="post.title"
        color="white"
        size="md-bg"
        class="new-ticket-posts__list-item"
        :class="{
          active: selectedPost ? post.title === selectedPost.title : null,
        }"
        @click.native="selectPostHandler(post)"
      >
        {{ post.title }}
      </ButtonBase>
    </div>
    <Loader v-else />
    <div class="new-ticket-posts__footer">
      <SignupControlButtons
        :back-button-text="$t('new_ticket_post.cancel')"
        :go-button-text="$t('new_ticket_post.next_step')"
        :is-disabled-go-btn="!selectedPost"
        @go-back="goBack"
        @go-on="goNextStep"
      />
    </div>
  </div>
</template>

<script>
import { setToLS } from '@/library/helpers';
import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    ButtonBase,
    SignupControlButtons,
    Loader,
  },
  data() {
    return {
      customs: [],
      selectedPost: null,
    };
  },
  mounted() {
    this.fetchAllCustoms();
  },
  methods: {
    async fetchAllCustoms() {
      const res = await this.$store.dispatch('getAllAvailableCustoms');
      this.customs = res;
    },
    selectPostHandler(post) {
      this.selectedPost = post;
    },
    goBack() {
      this.$router.push({ name: 'MainPage' });
    },
    goNextStep() {
      setToLS('new-ticket', {
        customs_post: this.selectedPost.value,
      });
      this.$router.push({ name: 'NewTicketInfo' });
    },
  },
};
</script>

<style lang="sass">
.new-ticket-posts .title
  @media screen and (max-width: 1199px)
    margin-top: 40px

  @media screen and (max-width: 767px)
    margin-top: 24px

.new-ticket-posts__list
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 38px

  @media screen and (max-width: 600px)
    gap: 12px

.new-ticket-posts__list .primary-btn
  width: 175px
  text-transform: unset!important

  @media screen and (max-width: 600px)
    width: 90px
    height: 44px
    font-size: 14px

  @media screen and (max-width: 350px)
    font-size: 12px


.new-ticket-posts__list .primary-btn.active
  border: none
  background-color: var(--secondary-color-dark-blue)
  color: var(--primary-color-white)

.new-ticket-posts .loader svg
  height: 64px
  width: 64px

  @media screen and (max-width: 600px)
    height: 44px
    width: 44px
</style>
